<template>
  <img
    v-if="isFullTextLogo"
    :src="color === 'white' ? fullTextLogoWhite : fullTextLogo"
    alt="Logo Book un artiste"
    class="logo__logo--full-text"
  />
  <span
    v-else
    :class="[wrapperStyle, 'logo__wrapper']">
    <svg
      :class="[logoStyle, { 'mr-xxs': isShowText } ,'logo__logo']"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 237 288">
      <defs v-if="color === 'gradient'">
        <linearGradient id="gradient">
          <stop offset="0%" stop-color="var(--color-gradient-blue)"/>
          <stop offset="100%" stop-color="var(--color-gradient-pink)"/>
        </linearGradient>
      </defs>
      <path d="M191.07 65.36H177a11.1 11.1 0 00-10.69 8.22 23 23 0 00-17.6-8.22 24.07 24.07 0 00-24.08 24.08v140.68H159V97.29a4.85 4.85 0 019.7 0v136.57a4.85 4.85 0 01-4.85 4.85h-39.2v4.22a24.06 24.06 0 0042.17 15.86 11.08 11.08 0 0010.67 8.21h13.59a8 8 0 008-8V73.51a8.07 8.07 0 00-8-8.15M75.57 238.7a4.85 4.85 0 01-4.85-4.85V97.28a4.85 4.85 0 019.7 0v132.83h34.43V89.45a24.05 24.05 0 00-41.33-16.76.23.23 0 01-.41-.16V35.74a8.19 8.19 0 00-8.19-8.19H48.51a8.12 8.12 0 00-8.12 8.12V259a8 8 0 008 8H62a11.1 11.1 0 0010.69-8.22 24.07 24.07 0 0042.17-15.86v-4.22H75.57"/>
    </svg>
    <template v-if="isShowText">
      Book un artiste
    </template>
  </span>
</template>

<script>

import FullTextLogo           from '../../../../public/assets/images/logo_bua_text.png';
import FullTextLogoWhite      from '../../../../public/assets/images/logo_bua_text_white.png';


export default {
  name: 'a-Logo',
  props: {
    isFullTextLogo: {
      type: Boolean,
      default: false,
    },
    isShowText: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
      validator: size => ['xs', 'sm', 'base', 'md', 'lg'].includes(size),
    },
    color: {
      type: String,
      default: 'black',
      validator: color => ['gradient', 'black', 'white'].includes(color),
    },
  },
  data: () => ({
    fullTextLogo: FullTextLogo,
    fullTextLogoWhite: FullTextLogoWhite,
  }),
  computed: {
    logoStyle() {
      return {
        [`logo__logo--${this.size}`]: true,
        [`logo__logo--${this.color}`]: true,
      };
    },
    wrapperStyle() {
      return { [`logo__wrapper--${this.size}`]: true, };
    },
  }
}

</script>

<style lang="scss">

.logo {
  $sizes: (
    'xs': 25px,
    'sm': 45px,
    'base': 70px,
    'md': 100px,
    'lg': 175px,
  );

  $colors: (
    'black': var(--color-black),
    'white': var(--color-white),
    'gradient': var(--color-gradient-main),
  );

  &__wrapper {
    display: flex;
    align-items: flex-end;
    font-size: var(--text-lg);
    font-weight: var(--font-bold);

    @each $color-key, $color-value in $colors {
      &--#{$color-key} {
        color: $color-value;
      }
    }
  }

  &__logo {
    &--full-text {
      height: 20px;
      pointer-events: none;
    }

    @each $color-key, $color-value in $colors {
      &--#{$color-key} {
        fill: $color-value;

        @if $color-key == 'gradient' {
          fill: url(#gradient);
        }
      }
    }

    @each $size-key, $size-value in $sizes {
      &--#{$size-key} {
        width: $size-value;
      }
    }
  }
}

</style>
